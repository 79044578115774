import React, { useContext, useEffect, useState } from 'react';
import { faFileInvoice } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import congesService from '@data/congesService';
import { useTranslation } from 'react-i18next';
import ToastCustom from '@components/UI/ToastCustom';

import Lottie from 'lottie-react';
import empty from '@assets/lottie/empty';

import Spinner from '@components/UI/Spinner';
import UserContext from '@context/UserContext';

const Documentation = () => {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const { currentUser } = useContext(UserContext);

  const fetchDocuments = async () => {
    try {
      const response = await congesService.get(
        '/v1/documentation?sameClient=0'
      );
      setDocuments(response.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownload = async (doc) => {
    try {
      const response = await congesService.get(`/v1/documentation/${doc.id}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', doc.real_name);
      document.body.appendChild(link);
      link.click();

      ToastCustom.validated(t('downloadSuccess'));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDocuments().then(() => setIsLoading(false));
  }, []);

  return (
    <>
      <h1 className={'mb-6 text-2xl'}>{t('documentation')}</h1>
      <div className='bg-white w-full rounded-md p-7 h-[700px]'>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <ul className='flex flex-col gap-y-5 my-2'>
              {documents?.length > 0 ? (
                documents.map((document, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        'shadow-leave bg-white flex justify-between flex-1 items-center w-full h-8 px-6 py-4 rounded-[15px]'
                      }
                    >
                      <div className='text-lg'>
                        <FontAwesomeIcon icon={faFileInvoice} size='xl' />
                        <span className='mx-4'>{document.name}</span>
                      </div>
                      <button
                        onClick={() => handleDownload(document)}
                        className='bg-primary1 text-white rounded-[30px] px-9 py-2 hover:bg-lightgrey-900 active:bg-lightgrey-500 transition-all duration-200'
                      >
                        {t('download').toUpperCase()}
                      </button>
                    </li>
                  );
                })
              ) : (
                <section className='flex flex-col items-center justify-center h-full'>
                  <Lottie
                    animationData={empty}
                    loop={true}
                    style={{
                      height: 300,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                  <h4>{t('noDocuments')}</h4>
                </section>
              )}
            </ul>
          </>
        )}
      </div>
    </>
  );
};

export default Documentation;
