import React from 'react';

import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faCircleCheck,
  faExclamationTriangle,
  faCircleXmark,
  faArrowsRotate,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const closeButton = ({ closeToast }) => (
  <i className='material-icons flex items-center' onClick={closeToast}>
    <FontAwesomeIcon size={'1x'} color='#586C9D' icon={faCircleXmark} />
  </i>
);

// TYPES : status, validated, submitted, corrected, error

const ToastCustom = {
  status: function (title = 'status') {
    toast(i18next.t(title), {
      // transition: swirl,
      position: 'bottom-right',
      closeOnClick: true,
      delay: 100,
      autoClose: 3000,
      className: 'material-icons',
      hideProgressBar: true,
      icon: <FontAwesomeIcon size={'1x'} color='#586C9D' icon={faCircleInfo} />,
      style: {
        padding: '0px',
        paddingRight: '16px',
        minHeight: '40px',
        width: '327px',
        borderRadius: '8px',
        background: '#F2F2F2',
        color: '#000',
      },
      closeButton: closeButton,
    });
  },

  validated: function (title = 'validated') {
    toast(i18next.t(title), {
      position: 'bottom-right',
      closeOnClick: true,
      delay: 100,
      autoClose: 3000,
      icon: (
        <FontAwesomeIcon size={'1x'} color='#408538' icon={faCircleCheck} />
      ),
      hideProgressBar: true,
      style: {
        padding: '0px',
        paddingRight: '16px',
        minHeight: '40px',
        width: '327px',
        borderRadius: '8px',
        background: '#D9F3EC',
        color: '#000',
        display: 'flex',
        alignItems: 'center',
      },
      closeButton: closeButton,
    });
  },

  submitted: function (title = 'submitted') {
    toast(i18next.t(title), {
      position: 'bottom-right',
      closeOnClick: true,
      delay: 100,
      autoClose: 3000,
      icon: (
        <FontAwesomeIcon
          size={'1x'}
          color='#FFBE0B'
          icon={faExclamationTriangle}
        />
      ),
      hideProgressBar: true,
      style: {
        padding: '0px',
        paddingRight: '16px',
        minHeight: '40px',
        width: '327px',
        borderRadius: '8px',
        background: '#FFF2CE',
        opacity: 0.8,
        color: '#000',
      },
      closeButton: closeButton,
    });
  },

  corrected: function (title = 'corrected') {
    toast(i18next.t(title), {
      position: 'bottom-right',
      closeOnClick: true,
      delay: 100,
      autoClose: 3000,
      icon: (
        <FontAwesomeIcon size={'1x'} color='#FB5607' icon={faArrowsRotate} />
      ),
      hideProgressBar: true,
      style: {
        padding: '0px',
        paddingRight: '16px',
        minHeight: '40px',
        width: '327px',
        borderRadius: '8px',
        background: '#FEDDCD',
        color: '#000',
      },
      closeButton: closeButton,
    });
  },

  error: function (title = 'error') {
    toast(i18next.t(title), {
      position: 'bottom-right',
      closeOnClick: true,
      delay: 100,
      autoClose: 3000,
      icon: (
        <FontAwesomeIcon size={'1x'} color='#C1040A' icon={faCircleXmark} />
      ),
      hideProgressBar: true,
      style: {
        padding: '0px',
        paddingRight: '16px',
        minHeight: '40px',
        width: '327px',
        borderRadius: '8px',
        background: '#F3CDCE',
        color: '#000',
      },
      closeButton: closeButton,
    });
  },
};

export default ToastCustom;
